<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-4">List Tokens</h4>
                        <b-tabs pills nav-class="bg-light rounded crypto_tabs_users" content-class="mt-4">
                            <b-tab class="" v-for="(item, index) in currencyList" :key="index" :title="item.pair"
                                :active="item.pair == 'ongoing' ? true : false">
                                <b-card-text>
                                    <div class="row mt-4">
                                        <div class="col-sm-12 col-md-6">
                                            <div id="tickets-table_length" class="dataTables_length">
                                                <label class="d-inline-flex align-items-center fw-normal">
                                                    Show&nbsp;
                                                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"
                                                        class="form-control form-select form-select-sm"></b-form-select>
                                                    &nbsp;entries
                                                </label>
                                            </div>
                                        </div>
                                        <!-- Search -->
                                        <div class="col-sm-12 col-md-6">
                                            <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                                <label class="d-inline-flex align-items-center">
                                                    Search:
                                                    <b-form-input v-model="filter" type="search"
                                                        class="form-control form-control-sm ms-2">
                                                    </b-form-input>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Table -->
                                    <div class="table-responsive table-hover mb-0">
                                        <b-table :items="tokenList[item.pair]" :fields="fields" responsive="sm"
                                            :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                                            :sort-desc.sync="sortDesc" :filter="filter"
                                            :filter-included-fields="filterOn" @filtered="onFiltered" :busy="isBusy"
                                            :show-empty="tokenList[item.pair] ? false : true">
                                            <template #empty>
                                                <div class="d-flex justify-content-center mb-3">
                                                    No Record Found
                                                </div>
                                            </template>

                                            <template #table-busy>
                                                <div class="text-center text-info my-2">
                                                    <b-spinner class="align-middle"></b-spinner>
                                                </div>
                                            </template>

                                            <template v-slot:cell(image)="row_data">
                                                <img class="rounded-circle avatar-xs" :src="row_data.item.image"
                                                    alt="icon" />
                                            </template>

                                            <template v-slot:cell(symbol)="data">
                                                <div class="mb-3">
                                                    {{ data.item.symbol }}
                                                </div>
                                            </template>


                                            <template v-slot:cell(name)="data">
                                                <div class="mb-3">
                                                    {{ data.item.name }}
                                                </div>
                                            </template>

                                            <template v-slot:cell(token_url)="data">
                                                <div class=" mb-3">
                                                    <a href="">{{ data.item.token_url }}</a>
                                                </div>
                                            </template>

                                            <template v-slot:cell(social_media_link)="data">
                                                <div class=" mb-3">
                                                    <strong>facebook:</strong> <a href="">{{
                                                            data.item.social_media_link.facebook
                                                    }}</a> <br />
                                                    <strong>twitter:</strong> <a href=""> {{
                                                            data.item.social_media_link.twitter
                                                    }}</a><br />
                                                    <strong>LinkdIn:</strong> <a href=""> {{
                                                            data.item.social_media_link.linkedin
                                                    }}</a><br />
                                                    <strong>Telegram:</strong> <a href=""> {{
                                                            data.item.social_media_link.telegram
                                                    }}</a>
                                                </div>
                                            </template>
                                            <!-- <template v-slot:cell(partnerships)="data">
                                                <div class="mb-3">
                                                    <a href=""> {{ data.item.partnerships[0] }}</a><br />
                                                    <a href=""> {{ data.item.partnerships[1] }}</a><br />
                                                    <a href=""> {{ data.item.partnerships[2] }}</a><br />
                                                    <a href=""> {{ data.item.partnerships[3] }}</a><br />
                                                    <a href=""> {{ data.item.partnerships[4] }}</a><br />
                                                    <a href=""> {{ data.item.partnerships[5] }}</a><br />
                                                </div>
                                            </template> -->

                                            <template v-slot:cell(verification_score)="data">
                                                <div class="px-5 mb-3">
                                                    {{ data.item.verification_score }}/{{ data.item.total_score }}
                                                </div>
                                            </template>

                                            <!-- <template v-slot:cell(token_rounds)="data">
                                            <div class=" mb-3">
                                                <strong>Round 1:</strong> <br/> 
                                                <small class="fw-bold">Start at:</small>  {{ data.item.token_rounds[0].rounds[0].started_at }}<br/>
                                                <small class="fw-bold">Expired at:</small> {{ data.item.token_rounds[0].rounds[0].expired_at }}<br/>
                                                <small class="fw-bold">Price:</small>     {{ data.item.token_rounds[0].rounds[0].price }}<br/>
                                                <small class="fw-bold">Currency:</small>  {{ data.item.token_rounds[0].rounds[0].currency }}<br/>
                                                <small class="fw-bold">Status:</small>    {{ (data.item.token_rounds[0].rounds[0].status === true) ? "Active" :"Not Active"  }}<br/>
                                                <strong>Round 2:</strong> <br/> 
                                                <small class="fw-bold">Start at:</small>  {{ data.item.token_rounds[0].rounds[1].started_at }}<br/>
                                                <small class="fw-bold">Expired at:</small> {{ data.item.token_rounds[0].rounds[1].expired_at }}<br/>
                                                <small class="fw-bold">Price:</small>     {{ data.item.token_rounds[0].rounds[1].price }}<br/>
                                                <small class="fw-bold">Currency:</small>  {{ data.item.token_rounds[0].rounds[1].currency }}<br/>
                                                <small class="fw-bold">Status:</small>    {{ (data.item.token_rounds[0].rounds[1].status === true) ? "Active" :"Not Active"  }}<br/> 
                                                <strong>Round 3:</strong> <br/> 
                                                <small class="fw-bold">Start at:</small>  {{ data.item.token_rounds[0].rounds[2].started_at }}<br/>
                                                <small class="fw-bold">Expired at:</small> {{ data.item.token_rounds[0].rounds[2].expired_at }}<br/>
                                                <small class="fw-bold">Price:</small>     {{ data.item.token_rounds[0].rounds[2].price }}<br/>
                                                <small class="fw-bold">Currency:</small>  {{ data.item.token_rounds[0].rounds[2].currency }}<br/>
                                                <small class="fw-bold">Status:</small>    {{ (data.item.token_rounds[0].rounds[2].status === true) ? "Active" :"Not Active"  }}<br/>
                                            </div>
                                        </template> -->

                                            <template v-slot:cell(started_at)="data">
                                                <div class="mb-3">
                                                    {{ data.item.started_at }}
                                                </div>
                                            </template>

                                            <template v-slot:cell(expired_at)="data">
                                                <div class="mb-3">
                                                    {{ data.item.expired_at }}
                                                </div>
                                            </template>
                                            <template v-slot:cell(activate_status)="data">
                                                <div class="form-check form-switch form-switch-md mb-3">
                                                    <input v-model="data.item.active_status"
                                                        @change="activateToken(data.item, 'active_status')"
                                                        class="form-check-input" type="checkbox"
                                                        id="SwitchCheckSizemd" />
                                                </div>
                                            </template>
                                            <!-- action section -->
                                            <template v-slot:cell(action)="data">
                                                <router-link :to="'/update/tokens/' + data.item.id">Edit</router-link>
                                            </template>
                                        </b-table>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="dataTables_paginate paging_simple_numbers float-end">
                                                <ul class="pagination pagination-rounded mb-0">
                                                    <!-- pagination -->
                                                    <b-pagination v-model="currentPage" :total-rows="tokenList[item.pair] ? tokenList[item.pair].length : ''
                                                    " :per-page="perPage">
                                                    </b-pagination>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-text>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ApiClass from "../../api/api";
import currencyList from "../../jsonFiles/launch_token.json";
import Swal from "sweetalert2";
export default {
    page: {
        title: "IEO Token List",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader },
    data() {
        return {
            enable: false,
            title: "Token List",
            items: [{
                text: "List Token",
                href: "#",
            },
            {
                text: "Tokens",
                active: true,
            },
            ],
            fields: [{
                key: "image",
                sortable: true,
            },
            {
                key: "symbol",
                sortable: true,
            },
            {
                key: "name",
                sortable: true,
            },
            {
                key: "token_url",
                sortable: true,
            },
            {
                key: "social_media_link",
                sortable: true,
            },
            // {
            //     key: "partnerships",
            //     sortable: true,
            // },
            {
                key: "verification_score",
                sortable: true,
            },
            // {
            //     key:"token_rounds",
            //     sortable: true,
            // },
            {
                key: "started_at",
                sortable: false,
            },
            {
                key: "expired_at",
                sortable: false,
            },
            {
                key: "activate_status",
                value: "activate_status",
                sortable: false,
            },
            {
                key: "action",
                sortable: false,
            },

            ],
            tokenList: [],
            isBusy: true,
            checkTrue: true,
            checkRes: true,
            checkFalse: false,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "id",
            sortDesc: true,
            currencyList: currencyList,
        };
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
        this.tokenGet();
    },
    methods: {
        async activateToken(e, colm) {
            let activate = e[colm] ? 1 : 0;
            // console.log({hihi : e[colm]});
            let result = await ApiClass.updatenodeRequest("launchpad/update_status/" + e.id + "/" + activate, true);
            console.log({ OOOPS : result });
            if (result.data.status_code == 1) {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: result.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: result.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

             this.tokenGet();
        },
        // async check(e, colm) {
        //     // var status = e.active_status == true ? 1 :0;
        //     await ApiClass.updateNodeRequest(
        //         "/getone/" + e.id + "/" + e[colm] + "/" + colm,
        //         true
        //     );
        // },
        async tokenGet() {
            var result = await ApiClass.getNodeRequest("launchpad/admin/token_get", true);
            console.log({pppoooopppp : result});
            if (result.data.status_code == 1) {
                this.isBusy = false;
                this.tokenList = result.data.data;
            }
        },
        // Search the table data with search input
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
    },
};
</script>

<style>
.user_switch_crypto.form-switch .form-check-input {
    width: 3em !important;
    height: 1.2rem !important;
    border-radius: 5em !important;
    cursor: pointer;
}

ul.nav.nav-pills.bg-light.rounded.crypto_tabs_users li a {
    text-transform: uppercase;
    padding: 0.6rem 3rem;
    font-size: 17px;
    font-weight: 500;
}
</style>
